
import './SettingsExport.css';

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { reactive } from 'vue';
import { defineComponent } from 'vue-demi';
import { useStores } from '@/store/Stores';

import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { errorHandling } from '@/utils';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  components: {
    InputText,
    Button,
    Checkbox,
    Dropdown
  },
  methods: {
    handleSubmit(isFormValid) {
      this.v$.$touch();
      if (!isFormValid) {
        return;
      }

      this.store
        .saveChanges({
          ftpDir: this.v$.ftpDir.$model,
          ftpHost: this.v$.ftpHost.$model,
          ftpPass: this.v$.ftpPass.$model,
          ftpUser: this.v$.ftpUser.$model,

          sendPhotos: this.v$.sendPhotos.$model,
          saveOriginals: this.v$.saveOriginals.$model,
          storeAsZip: this.v$.storeAsZip.$model,

          dirName: this.v$.dirName.$model,
          initialName: this.v$.initialName.$model,
          initialIndex: this.v$.initialIndex.$model,

          format: this.v$.format.$model
        })
        .then(() => {
          this.toast.add({ severity: 'success', summary: this.t('success'), life: 3000 });
        })
        .catch((error) => {
          const { title, message } = errorHandling(error, this.t);
          this.toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
        });
    }
  },
  setup() {
    const { companyExportStore, companyExportStoreState } = useStores();
    companyExportStore.init();

    const toast = useToast();
    const { t } = useI18n();

    const optionsFileType = [
      { value: 'as_is', label: 'Default' },
      { value: 'jpeg', label: 'Jpeg' },
      { value: 'png', label: 'Png' }
    ];

    const v$ = useVuelidate(
      reactive({
        ftpHost: { required },
        ftpUser: { required },
        ftpPass: { required },
        ftpDir: { required },
        sendPhotos: {},
        saveOriginals: {},
        storeAsZip: {},
        dirName: {},
        initialName: {},
        initialIndex: {},
        format: {}
      }),
      companyExportStoreState
    );

    return {
      store: companyExportStore,
      state: companyExportStoreState,

      optionsFileType,

      v$,
      toast,
      t
    };
  }
});
